import { Controller } from '@hotwired/stimulus'

// Connects to data-controller='flash-message'
export default class extends Controller {
    connect() {
        setTimeout(() => {
            this.element.remove();
        }, 6000) // Remove flash message after 6 seconds
    }

    close() {
        this.element.remove()
    }
}