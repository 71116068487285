import { Controller } from "stimulus";

export default class extends Controller {
  static values = { url: String }

  connect() {
    window.addEventListener("pageshow", this.handlePageShow.bind(this));
  }

  handlePageShow(event) {
    if (event.persisted) {
      this.checkSession();
    }
  }

  checkSession() {
    fetch('logged_in', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    .then(response => response.json())
    .then(data => {
      if (!data.logged_in) {
        window.location.href = '/login';
      }
    })
    .catch(error => console.error('Chyba při ověřování session:', error));
  }
}
