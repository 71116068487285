import {Application} from "@hotwired/stimulus"
import {registerControllers} from 'stimulus-vite-helpers'

const application = Application.start()

const controllers = import.meta.glob('./controllers/**/*.js', {eager: true})
registerControllers(application, controllers)

application.debug = true
window.Stimulus = application

export {application}
