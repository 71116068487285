// app/javascript/controllers/table_controller.js
import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import "bootstrap-table"; // Importuje bootstrap-table a další potřebné věci

export default class extends Controller {
  connect() {
    this.initializeTable();
  }

  initializeTable() {
    $(document).ready(() => {
      this.modifySelectOptions();
      this.handleTableLinks();
    });
  }

  initializeTooltips() {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  modifySelectOptions() {
    $("select option").each(function () {
      if ($(this).text() === '→') {
        $(this).text('→ jednorázová');
      }
      if ($(this).text() === '↻') {
        $(this).text('↻ pravidelná');
      }
    });
  }

  handleTableLinks() {
    $("table").on("click", "td.tablelink", function (e) {
      var href = $(this).data("href");
      window.location.href = href;
      e.stopPropagation();
    });
  }
}
