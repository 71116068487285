import * as Sentry from "@sentry/browser";

if (window.APP_ENV === null) {
    Sentry.init({
        dsn: "https://6a2737ebda883cfb91108660bcc7cc6d@o1229290.ingest.sentry.io/4506246797721600",
        release: "mafin-clients@1.0.0-SNAPSHOT",
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [/^https:\/\/.+\.mafin\.ffdev.cz.+/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

import * as Turbo from '@hotwired/turbo'

Turbo.start()

import * as ActiveStorage from '@rails/activestorage'

ActiveStorage.start()

const channels = import.meta.glob('./**/*_channel.js', {eager: true})

import {application} from "~/stimulus"

import('bootstrap-icons/font/bootstrap-icons.scss')


import jQuery from 'jquery'

import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap;
import * as Popper from "@popperjs/core"
import moment from 'moment'
window.moment = moment;


Object.assign(window, {$: jQuery, jQuery})

document.addEventListener("turbo:load", function() {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipTriggerList.forEach(function(tooltipTriggerEl) {
        new bootstrap.Tooltip(tooltipTriggerEl);
    });
});

function getOSInfo() {
    let os = "unknown";
    let osVersion = "unknown";
  
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
  
    if (platform.indexOf('Win') !== -1) {
      os = 'windows';
      const versionMatch = userAgent.match(/Windows NT (\d+\.\d+)/);
      if (versionMatch) {
        osVersion = versionMatch[1];
      }
    } else if (platform.indexOf('Mac') !== -1) {
      os = 'macos';
      const versionMatch = userAgent.match(/Mac OS X (\d+[._]\d+[._]?\d*)/);
      if (versionMatch) {
        osVersion = versionMatch[1].replace(/_/g, '.');
      }
    } else if (/Android/.test(userAgent)) {
      os = 'android';
      const versionMatch = userAgent.match(/Android (\d+\.\d+)/);
      if (versionMatch) {
        osVersion = versionMatch[1];
      }
    } else if (/Linux/.test(platform)) {
      os = 'linux';
    }
  
    return {
      osType: os,
      osVersion: osVersion
    };
}

document.addEventListener('DOMContentLoaded', () => {
    const osInfo = getOSInfo();
    document.cookie = `os_type=${osInfo.osType}; path=/`;
    document.cookie = `os_version=${osInfo.osVersion}; path=/`;
});